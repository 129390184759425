import React from 'react';

const MotorBikeIcon = ({size = null}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
             className="iconify iconify--fa" width="22" height="22" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 2304 1280">
            <path
                d="M2301 780q12 103-22 198.5t-99 163.5t-158.5 106t-196.5 31q-161-11-279.5-125T1411 880q-12-111 27.5-210.5T1557 499l-71-107q-96 80-151 194t-55 244q0 27-18.5 46.5T1216 896H891q-23 164-149 274t-294 110q-185 0-316.5-131.5T0 832t131.5-316.5T448 384q76 0 152 27l24-45Q501 256 320 256h-64q-26 0-45-19t-19-45t19-45t45-19h128q78 0 145 13.5T645.5 180t71.5 39.5t51 36.5h627l-85-128h-222q-30 0-49-22.5T1025 53q4-23 23-38t43-15h253q33 0 53 28l70 105l114-114q19-19 46-19h101q26 0 45 19t19 45v128q0 26-19 45t-45 19h-179l115 172q131-63 275-36q143 26 244 134.5T2301 780zM448 1152q115 0 203-72.5T762 896H448q-35 0-55-31q-18-32-1-63l147-277q-47-13-91-13q-132 0-226 94t-94 226t94 226t226 94zm1408 0q132 0 226-94t94-226t-94-226t-226-94q-60 0-121 24l174 260q15 23 10 49t-27 40q-15 11-36 11q-35 0-53-29l-174-260q-93 95-93 225q0 132 94 226t226 94z"
                fill="currentColor"></path>
        </svg>
    );
};

export default MotorBikeIcon;