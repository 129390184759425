import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useStyleContext } from "../../../customHooks/useStyleContext";

const ErrorComponent = (props) => {
    const error = useRouteError();
    const { env } = useStyleContext();

    if (props) {
        console.error(props);
    }

    const sendToSentry = () => {
        if (error && env !== "dev") {
            Sentry.captureException(error);
        } else {
            console.error(error);
        }
    }

    useEffect(() => {
        sendToSentry();
    }, [error]);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
            <span className="display-3 text-center">Oups ! 😢</span>
            <h1 className="display-1 text-center">Une erreur est survenue</h1>
            <p className="mt-3">Une erreur est survenue lors de la navigation, et nous avons été notifiés.</p>
            <p>Nous nous excusons pour la gêne occasionnée.</p>
        </div>
    );
};

export default ErrorComponent;