import React from 'react';
import vehicleDefaultImage from "../../../images/no-vehicle-picture.png";
import VehicleTypeDefaultIconByName from "../VehicleTypeDefaultIconByName/VehicleTypeDefaultIconByName";

const VehiclePicture = ({className, url, vehicleType, height = 76, square = false}) => {
    return (
        <>
            {vehicleType?.name && vehicleType?.name !== "Voiture" && !url ?
                <div style={{background: "#d7e0e5", color: "#b8c7d1", height: height}} className={"d-flex justify-content-center align-items-center bradius-5"}>
                    <VehicleTypeDefaultIconByName size={"2"} name={vehicleType?.default_icon_name} />
                </div>
            :
                <img className={className} style={{width: '100%', minHeight: height, aspectRatio: square ? '1/1' : '', objectFit: "contain", borderRadius: 5}} src={(url && url !== "" ) ? url : vehicleDefaultImage} alt={"image"}/>
            }
        </>
    );
};

export default VehiclePicture;
