import React from 'react';

import AcIcon from "./images/AcIcon"
import GasIcon from "./images/GasIcon"
import SeatIcon from "./images/SeatIcon";
import DoorIcon from "./images/DoorIcon";
import ShifterIcon from "./images/ShifterIcon";
import BagIcon from "./images/BagIcon";

const iconComponents = {
    seat: SeatIcon,
    ac: AcIcon,
    gas: GasIcon,
    door: DoorIcon,
    shifter: ShifterIcon,
    bags: BagIcon,
}

const VehicleSpecItem = ({websiteStyle, iconName = 'ac', label}) => {

    const IconComponent = iconComponents[iconName]
    if(!IconComponent && !label) return null
    return (
        <div className={"d-flex align-items-center my-1"}>
            <div className={"mr-2 w25 d-flex justify-content-center"}>
                {IconComponent && <IconComponent fill={websiteStyle?.colors?.['c-main']}/> }
            </div>
            <span className={"regular fs12 "}>{label}</span>
        </div>
    );
};

export default VehicleSpecItem;
