import React from 'react';

const ScooterIcon = ({size = null}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
             className="iconify iconify--mdi" width="22" height="22" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 24 24">
            <path
                d="M7.82 19H15v-1c0-2.21 1.79-4 4-4h.74l-1.9-8.44A2.009 2.009 0 0 0 15.89 4H12v2h3.89l1.4 6.25h-.01A6.008 6.008 0 0 0 13.09 17H7.82a2.996 2.996 0 0 0-3.42-1.94c-1.18.23-2.13 1.2-2.35 2.38A3.002 3.002 0 0 0 5 21c1.3 0 2.4-.84 2.82-2M5 19c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1m14-4c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3m0 4c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z"
                fill="currentColor"></path>
        </svg>
    );
};

export default ScooterIcon;