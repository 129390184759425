import React from 'react';

const RobotIcon = ({size = null, style= ""}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
             role="img" className="iconify iconify--icon-park-outline" width="20" height="20"
             preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48">
            <g fill="none" stroke="currentColor" strokeWidth="4">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M5 35a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v7H5v-7Zm37-17h-8l-6-6l6-6h8"></path>
                <circle cx="8" cy="12" r="4"></circle>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 12h16m-18 4l8 17"></path>
            </g>
        </svg>
    );
};

export default RobotIcon;