import React from 'react';

const BagIcon = ({fill}) => {
    return (
        <svg width="13" height="10" viewBox="0 0 13 10" xmlns="http://www.w3.org/2000/svg">
            <g
                fill={fill ? fill : "#7165e3"}
                fillRule="nonzero"
            >
                <path
                    d="M12.515 2H9.75v8h2.765c.268 0 .485-.158.485-.353V2.353c0-.195-.217-.353-.485-.353zM.485 2C.217 2 0 2.158 0 2.353v7.294c0 .195.217.353.485.353H3.25V2H.485zM9.75 1.66h-.783C8.753.717 7.737 0 6.517 0c-1.22 0-2.237.717-2.45 1.66H3.25V10h6.5V1.66zm-4.756 0c.195-.534.804-.925 1.522-.925.72 0 1.328.391 1.523.925H4.994z"/>
            </g>
        </svg>


    );
};

export default BagIcon;
