import React from "react";
import Loader from "./Loader";

export const LoaderContainer = ({ color }) => {
    return (
        <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: '90vh' }}>
            <h1>Chargement...</h1>
            <Loader color={color} />
        </div>
    );
}