import React from 'react';

const DumpTruckIcon = ({size = null}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
             className="iconify iconify--mdi" width="22" height="22" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 22 22">
            <path fill="currentColor"
                  d="M20 8h-5v6H2v3h1a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4M6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5m12 0a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5M17 12V9.5h2.5l1.96 2.5H17m1-5h-4v6H3L1.57 8H1V6h12l1-1h4v2Z"></path>
        </svg>
    );
};

export default DumpTruckIcon;