import React from 'react';

const BusIcon = ({size = null}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img"
             className="iconify iconify--mdi" width="22" height="22" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 22 22">
            <path fill="currentColor"
                  d="M3 6c-1.11 0-2 .89-2 2v7h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2V8c0-1.11-.89-2-2-2H3m-.5 1.5h4V10h-4V7.5m5.5 0h4V10H8V7.5m5.5 0h4V10h-4V7.5m5.5 0h2.5V13L19 11V7.5m-13 6A1.5 1.5 0 0 1 7.5 15A1.5 1.5 0 0 1 6 16.5A1.5 1.5 0 0 1 4.5 15A1.5 1.5 0 0 1 6 13.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5Z"></path>
        </svg>
    );
};

export default BusIcon;