import React from 'react';

const DoorIcon = ({fill}) => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path  d="M-835-1291H605V945H-835z"/>
                <g transform="translate(-448 -104)">
                    <g fillRule="nonzero">
                        <path d="M453.654 108.64a.28.28 0 0 0 .19-.073l.539-.5a.237.237 0 0 0 0-.353.284.284 0 0 0-.381 0l-.539.5a.237.237 0 0 0 0 .353.28.28 0 0 0 .19.074zM455.079 107.714l-1.616 1.5a.237.237 0 0 0 0 .353.28.28 0 0 0 .19.074.28.28 0 0 0 .191-.074l1.616-1.5a.237.237 0 0 0 0-.353.284.284 0 0 0-.381 0z"
                              fill={fill ? fill : "#7165e3"}
                        />
                        <path d="M461.808 105.58c-.31-.579-.934-.94-1.627-.94h-5.652c-1.082 0-2.115.434-2.834 1.19l-2.74 2.881a3.421 3.421 0 0 0-.955 2.39l.488 3.52c.07.505.32.981.705 1.341.468.438 1.09.679 1.752.679h9.888c.644 0 1.167-.49 1.167-1.091v-9.209a1.62 1.62 0 0 0-.192-.76zm-11.542 4.218 2.738-2.88a2.11 2.11 0 0 1 1.526-.641h5.651c.027 0 .05.013.069.064v4.3h-10.477c.054-.29.203-.538.493-.843zm10.276 4.66h-10.5c-.162 0-.292-.121-.292-.272 0-.15.13-.273.292-.273h10.5c.16 0 .291.122.291.273 0 .15-.13.273-.291.273zm0-1.636h-1.75c-.162 0-.292-.122-.292-.272 0-.151.13-.273.292-.273h1.75c.16 0 .291.122.291.273 0 .15-.13.272-.291.272z"
                              fill={fill ? fill : "#7165e3"}
                        />
                    </g>
                </g>
            </g>
        </svg>

    );
};

export default DoorIcon;
