import React, {useEffect, useState} from 'react';
import cx from "classnames"
import sandbox from "../../../service/Sandbox";

const Header = ({styles, headerAlignment, rightComponent}) => {

    const [state, setState] = useState({
        logo_url: null,
        imageStyle: {},
        backGroundColor: "#FFFFFF",
        textColor: "#000000",
        showLogo : true,
        logoLink : null
    });

    useEffect(() => {
        let url = null
        let imageStyle = {}
        let backGroundColor = "#FFFFFF"
        let textColor = "#000000"
        let showLogo = true
        let logoLink = null

        if(styles?.configs?.['content_config-logo']) {
            url = styles?.configs?.['content_config-logo']
        }
        if(styles?.configs?.['content_config-logo_height']) {
            imageStyle.height = styles?.configs?.['content_config-logo_height']
        }
        if(styles?.configs?.['content_config-logo_width']) {
            imageStyle.width = styles?.configs?.['content_config-logo_width']
        }
        if(styles?.header?.configs?.["config-app_background_color"]) {
            backGroundColor = styles?.header?.configs?.["config-app_background_color"]
        }
        if(styles?.header?.configs?.["config-app_text_color"]) {
            textColor = styles?.header?.configs?.["config-app_text_color"]
        }
        if(styles?.header?.configs?.["config-hide_logo_on_app"]) {
            showLogo = false
        }
        if(styles?.header?.configs?.["config-app_logo_url"]) {
            logoLink = styles?.header?.configs?.["config-app_logo_url"]
        }
        let newState = {logo_url: url, imageStyle, backGroundColor, textColor, showLogo, logoLink}
        console.log("styles", styles, newState)
        setState(newState)
    }, [])

    const onLogoClick = () => {
        if(sandbox.inIframe()) {
            // window.top.location.assign(state.logoLink ?? "/")
            window.top.location.href = state.logoLink ?? "/"
        } else {
            // window.location.assign(state.logoLink ?? "/")
            window.location.href = state.logoLink ?? "/"

        }
    }

    let agencyName = null
    if(window?.agency) {
        agencyName = window.agency?.public_name !== "" ? window.agency?.public_name : window.agency?.name
    }

    return (
        <header
            id="header"
            className={cx("h75 d-flex  align-items-center w-100 shadow-sm p-0",headerAlignment, {"justify-content-center":state.showLogo && !headerAlignment}, {"justify-content-between": !state.showLogo})}
            style={{position: 'fixed', zIndex: 1021, backgroundColor: state.backGroundColor, color: state.textColor}}
        >
            {state.showLogo && (<>
                <div className={"px-md-4"}>
                    <p onClick={onLogoClick} className={"pointer ms-2"}>
                            {state.logo_url ?
                                (
                                    <img src={state.logo_url} style={state.imageStyle} className="logo" alt="title"/>
                                ) : (
                                    <span className="h50 bg-main p-3 c-white disabled medium bradius-5">Accueil</span>
                                )
                            }
                    </p>
                </div>
                {agencyName && (
                    <div className={"d-flex align-items-center ms-2 flex-fill"}>
                        <span className={"fs16 medium no_line_break"}>{agencyName}</span>
                    </div>
                )}
            </>)}
            {rightComponent}
        </header>
    );
};

export default Header;
