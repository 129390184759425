import React from 'react';
import RobotIcon from "./Icons/RobotIcon";
import ScooterIcon from "./Icons/ScooterIcon";
import BoatIcon from "./Icons/BoatIcon";
import MotorBikeIcon from "./Icons/MotorBikeIcon";
import BikeIcon from "./Icons/BikeIcon";
import CarIcon from "./Icons/CarIcon";
import AirplaneIcon from "./Icons/AirplaneIcon";
import BackhoeLoaderIcon from "./Icons/BackhoeLoaderIcon";
import BusIcon from "./Icons/BusIcon";
import DroneIcon from "./Icons/DroneIcon";
import DumpTruckIcon from "./Icons/DumpTruckIcon";
import HelicopterIcon from "./Icons/HelicopterIcon";
import TruckIcon from "./Icons/TruckIcon";
import TractorIcon from "./Icons/TractorIcon";
import {ALL_ICONS} from "../../../utils/typeIcons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VehicleTypeDefaultIconByName = ({name, size}) => {
    switch (name) {
        case "RobotIcon":
            return <RobotIcon size={size} />
        case "ScooterIcon":
            return <ScooterIcon size={size} />
        case "BoatIcon":
            return <BoatIcon size={size} />
        case "MotorBikeIcon":
            return <MotorBikeIcon size={size} />
        case "BikeIcon":
            return <BikeIcon size={size} />
        case "CarIcon":
            return <CarIcon size={size} />
        case "AirplaneIcon":
            return <AirplaneIcon size={size} />
        case "BackhoeLoaderIcon":
            return <BackhoeLoaderIcon size={size} />
        case "BusIcon":
            return <BusIcon size={size} />
        case "DroneIcon":
            return <DroneIcon size={size} />
        case "DumpTruckIcon":
            return <DumpTruckIcon size={size} />
        case "HelicopterIcon":
            return <HelicopterIcon size={size} />
        case "TruckIcon":
            return <TruckIcon size={size} />
        case "TractorIcon":
            return <TractorIcon size={size} />
        default:
            if(ALL_ICONS[name]) {
                const icon = ALL_ICONS[name];
                if(icon.lib === 'fontawesome') {
                    return <span
                        className={"mx-1"}
                        style={{
                            fontSize: size ?? 18,
                            // transform: size && `scale(${size})`
                        }}
                    ><FontAwesomeIcon icon={icon.icon} /></span>
                }
            }
            return null;
    }
};

export default VehicleTypeDefaultIconByName;