import React from 'react';

const AirplaneIcon = ({size = null}) => {
    return (
        <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
             className="iconify iconify--mdi" width="22" height="22" preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 22 22">
            <path fill="currentColor"
                  d="M20.56 3.91c.59.59.59 1.54 0 2.12l-3.89 3.89l2.12 9.19l-1.41 1.42l-3.88-7.43L9.6 17l.36 2.47l-1.07 1.06l-1.76-3.18l-3.19-1.77L5 14.5l2.5.37L11.37 11L3.94 7.09l1.42-1.41l9.19 2.12l3.89-3.89c.56-.58 1.56-.58 2.12 0Z"></path>
        </svg>
    );
};

export default AirplaneIcon;