import React from 'react';

const GasIcon = ({fill}) => {
    return (
        <svg width="10" height="13" viewBox="0 0 10 13" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m9.927 1.648-1-1a.25.25 0 1 0-.354.354l.823.823-.823.823a.25.25 0 0 0-.073.177v.75c0 .551.448 1 1 1v4.25a.25.25 0 0 1-.5 0v-.5a.75.75 0 0 0-.75-.75H8v-6.5c0-.552-.448-1-1-1H2c-.551 0-1 .448-1 1v9c-.552 0-1 .448-1 1v.75c0 .138.112.25.25.25h8.5a.25.25 0 0 0 .25-.25v-.75c0-.552-.448-1-1-1v-2h.25a.25.25 0 0 1 .25.25v.5a.75.75 0 0 0 1.5 0v-7a.25.25 0 0 0-.073-.177zM7 4.789c0 .158-.112.286-.25.286h-4.5c-.138 0-.25-.128-.25-.286V1.36c0-.158.112-.285.25-.285h4.5c.138 0 .25.127.25.285v3.43z"
                fill={fill ? fill : "#7165e3"}
                fillRule="nonzero"
            />
        </svg>
    );
};

export default GasIcon;
