import React from 'react';

const TractorIcon = ({size = null}) => {
    return (
            <svg style={size && {transform: `scale(${size})`}} xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                 role="img" className="iconify iconify--mdi" width="22" height="22"
                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 22 22">
                <path fill="currentColor" d="M5 4v7.26c-1.8.64-3 2.34-3 4.24C2 18 4 20 6.5 20c2.29 0 4.21-1.72 4.47-4h4.2c-.11.32-.17.66-.17 1a3 3 0 0 0 3 3a3 3 0 0 0 3-3c0-.34-.06-.68-.18-1H22v-3c0-1.11-.89-2-2-2h-4.96l-1.39-7H5m2 2h5l1 5v3h-2.26A4.53 4.53 0 0 0 7 11.03V6m-.5 7.25a2.25 2.25 0 0 1 2.25 2.25a2.25 2.25 0 0 1-2.25 2.25a2.25 2.25 0 0 1-2.25-2.25a2.25 2.25 0 0 1 2.25-2.25M18 15.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5Z"></path>
            </svg>
    );
};

export default TractorIcon;