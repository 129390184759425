import React, { useMemo } from 'react'
import {useStyleContext} from "../../../customHooks/useStyleContext";

const AmountDisplay = ({tariffDisplayMode, invoicingMode, exclAmount, inclAmount, defaultAmount, showTTC = false}) => {
    const styleContext = useStyleContext();
    const { currency } = styleContext;
    const currencySymbol = currency?.symbol ?? '';

    const tariffDisplayModeState =  useMemo(() => {
        return tariffDisplayMode || 'pricing_group';
    }, [tariffDisplayMode])

    const displayedAmount = useMemo(() => {

        let amount = inclAmount ?? "";
        let tariffMode = ""
        const usePricingGroupMode = tariffDisplayModeState === "pricing_group" || !tariffDisplayModeState;
        // console.log({tariffDisplayMode});
        // console.log({usePricingGroupMode});
        // console.log({invoicingMode});
        if(tariffDisplayMode === "ht" || (usePricingGroupMode && invoicingMode === 'ht')) {
            // if(invoicingMode === 'ht') {
                tariffMode = " HT";
                amount = exclAmount;
            // }
        }
        if(!isNaN(parseFloat(amount))) {
            amount = parseFloat(amount).toFixed(2);
        } else if (amount == '' && defaultAmount) {
            amount = defaultAmount;
        }
        if(amount == null) { return null }

        return {
            amount,
            tariffMode: tariffMode == '' && showTTC ? 'TTC' : tariffMode
        }
    }, [invoicingMode, tariffDisplayModeState, exclAmount, inclAmount]);

    if(displayedAmount == null) { return null }
    // console.log(displayedAmount);
    
    return (<>{displayedAmount.amount}{currencySymbol}{displayedAmount.tariffMode && ` ${displayedAmount.tariffMode}`}</>)
}

export default AmountDisplay
