import React, {useMemo} from 'react';
import {Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
const BookingCouponAmount = ({couponAmount, couponType, invoicingMode, vatRate, currencySymbol, className}) => {

    const couponAmountTTC = useMemo(() => {
        if(!couponType || couponType !== "amount") {return null}
        if(invoicingMode && invoicingMode === "ht") { return null }
        if(!vatRate) {return null}
        return couponAmount * (1 + vatRate/100)
    }, [couponAmount, couponType, invoicingMode, vatRate])


    return (
        <div className={cx("d-flex flex-column align-items-end", className)}>
            {couponAmountTTC != null ? (
                <p className={'text-right'}>
                    <span className={"bold"}>-{couponAmountTTC.toFixed(2)} {currencySymbol}<span
                        className={"fs12"}> TTC</span></span>
                    <Tooltip title={<span
                        className={"fs14"}>-{couponAmount?.toFixed(2) ?? 0} {currencySymbol} HT</span>}
                             placement={"top"}>
                        <span className={"ms-2"}><FontAwesomeIcon icon={faInfoCircle}/></span>
                    </Tooltip>
                </p>
            ) : (
                <p className={'text-right bold'}>-{couponAmount?.toFixed(2) ?? 0} {currencySymbol}</p>
            )}
        </div>
    );
};

export default BookingCouponAmount;
