import React from 'react';

const SeatIcon = ({fill}) => {
    return (
        <svg width="11" height="15" viewBox="0 0 11 15" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.708.51A1.08 1.08 0 0 0 9.9.154h-.861c-.635 0-1.24.52-1.35 1.161l-.054.317c-.054.319.019.62.207.85.188.23.465.356.781.356h1.112c.154 0 .303-.031.442-.088l-.118 1.036a.931.931 0 0 0-.37-.076H8.42c-.543 0-1.077.444-1.19.989L6.11 10.12l-5.292-.39c-.286-.02-.525.079-.673.274-.138.182-.179.428-.114.69l.615 2.484c.134.538.683.976 1.224.976h6.762c.549 0 1.043-.456 1.1-1.015l.868-8.39c.005-.049.006-.096.004-.143l.297-2.602c.042-.112.07-.23.077-.354l.02-.29c.022-.32-.08-.623-.289-.852z"
                fill={fill ? fill : "#7165e3"}
                fillRule="nonzero"/>
        </svg>

    );
};

export default SeatIcon;
