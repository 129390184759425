import React from 'react';

const ShifterIcon = ({fill}) => {
    return (
        <svg width="9" height="12" viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25 10.095c0-.434-.336-.785-.75-.785 0-.434-.336-.786-.75-.786h-1.5V3.59A1.58 1.58 0 0 0 6 2.238C6 1.371 5.327.667 4.5.667S3 1.37 3 2.238c0 .58.304 1.08.75 1.353v4.933h-1.5c-.414 0-.75.352-.75.786-.414 0-.75.351-.75.785-.414 0-.75.352-.75.786 0 .434.336.786.75.786h7.5c.414 0 .75-.352.75-.786 0-.434-.336-.786-.75-.786zM4.125 1.452c.207 0 .375.176.375.393a.384.384 0 0 1-.375.393.384.384 0 0 1-.375-.393c0-.217.168-.393.375-.393z"
                fill={fill ? fill : "#7165e3"}
                fillRule="nonzero"/>
        </svg>

    );
};

export default ShifterIcon;
