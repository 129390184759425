import React from 'react';
import LoaderSpinner from "react-loader-spinner";
const Loader = ({color = 'white'}) => {
    return (
        <LoaderSpinner
            type="ThreeDots"
            color={color}
            height={30}
            width={40}
        />
    );
};

export default Loader;
