import React from 'react';

const VehicleDefaultModel = ({vehicle, category}) => {
    if(vehicle) {
        return (
            <span className="no-tr">{vehicle?.category?.vehicle_model ? vehicle?.category?.vehicle_model : <>{vehicle?.brand} {vehicle?.model}</>}</span>
        );
    } else if (category) {
        return (
            <span className="no-tr">{category?.vehicle_model ? category?.vehicle_model : category?.name}</span>
        );
    } else {
        return (
            <span/>
        );
    }
};

export default VehicleDefaultModel;
