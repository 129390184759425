import React from 'react';
import Loader from "../Loader/Loader";

const LoadingContainer = ({children, loading, title = "Chargement...", color = 'white'}) => {
    return (
        <>
            {loading ?
                <div className={"d-flex flex-column h-100 justify-content-center align-items-center"} style={{height : 'var(--app-height)'}}>
                    <Loader color={color} />
                    <p className={"fst-italic medium"}>{title}</p>
                </div>
            :
                <>{children}</>
            }
        </>
    );
};

export default LoadingContainer;
