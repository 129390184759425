import React from 'react';

const AcIcon = ({fill}) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.08 8.017H17.38l.92-1.516a.765.765 0 0 0-.302-1.073.847.847 0 0 0-1.127.288l-.919 1.516-1.322-2.182h1.838c.456 0 .825-.351.825-.785 0-.434-.37-.785-.825-.785h-1.838l1.349-2.225a.765.765 0 0 0-.302-1.073.847.847 0 0 0-1.127.287l-1.348 2.225-.92-1.516a.847.847 0 0 0-1.126-.287.765.765 0 0 0-.302 1.073l.92 1.516H9.128l.92-1.516A.765.765 0 0 0 9.747.89a.847.847 0 0 0-1.127.287l-.919 1.516L6.352.47A.847.847 0 0 0 5.226.182a.765.765 0 0 0-.302 1.073L6.273 3.48H4.435c-.456 0-.825.351-.825.785 0 .434.37.785.825.785h1.838L4.95 7.232l-.92-1.516a.847.847 0 0 0-1.126-.288.765.765 0 0 0-.302 1.073l.92 1.516H.824c-.456 0-.825.352-.825.785 0 .434.37.786.825.786h2.697l-.919 1.516a.765.765 0 0 0 .302 1.073.853.853 0 0 0 1.126-.288l.92-1.516 1.322 2.181H4.435c-.456 0-.825.352-.825.786 0 .434.37.785.825.785h1.838L4.924 16.35a.765.765 0 0 0 .302 1.073.853.853 0 0 0 1.126-.288l1.35-2.225.918 1.516a.853.853 0 0 0 1.126.287.765.765 0 0 0 .302-1.072l-.919-1.516h2.645l-.919 1.516a.765.765 0 0 0 .302 1.073.853.853 0 0 0 1.126-.288l.92-1.516 1.348 2.225a.852.852 0 0 0 1.126.287.765.765 0 0 0 .303-1.072l-1.35-2.225h1.839c.455 0 .824-.351.824-.785 0-.434-.369-.786-.824-.786H14.63l1.322-2.18.919 1.515a.852.852 0 0 0 1.126.287.765.765 0 0 0 .302-1.072l-.919-1.516h2.698c.455 0 .825-.352.825-.786 0-.433-.37-.785-.825-.785zm-7.354 4.537H8.177L5.903 8.802 8.177 5.05h4.55L15 8.802l-2.275 3.752z"
                fill={fill ? fill : "#7165e3"}
                fillRule="nonzero"
            />
        </svg>

    );
};

export default AcIcon;
