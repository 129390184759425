import {
    faAmbulance, faBabyCarriage, faBed, faBicycle, faBook, faBox, faBuilding, faBus, faBusAlt, faCamera, faCampground,
    faCar, faCaravan, faCarrot, faCarSide, faCat, faCoffee, faCouch, faCrow, faDesktop, faDice, faDog, faDolly,
    faDoorOpen, faDrum, faEnvelope, faExclamationTriangle, faEye, faFeather, faGamepad, faGasPump, faGift, faGlasses,
    faGlobe, faGlobeEurope, faGuitar, faHamburger, faHammer, faHandshake, faHardHat, faHatCowboy, faHeadphonesAlt,
    faHelicopter, faHome, faHorse, faHotel, faHourglass, faIceCream, faLaptop, faLeaf, faLifeRing, faLightbulb,
    faLock, faMobileAlt, faMotorcycle, faMugHot, faPaw, faPlane, faPlug, faReceipt, faRecordVinyl, faRecycle, faRobot,
    faRocket, faSeedling, faShieldAlt, faShip, faShoppingBag, faShoppingBasket, faShoppingCart, faShuttleVan,
    faSnowflake, faSnowplow, faSocks, faSolarPanel, faStar, faStore, faSuitcase, faSun, faTabletAlt, faTag,
    faTheaterMasks, faToolbox, faTools, faTractor, faTrailer, faTrash, faTrophy, faTruck, faTruckPickup, faTshirt,
    faUmbrella, faUnlock, faUtensils, faVest, faVideo, faVolleyballBall, faWeightHanging, faWineBottle, faWrench
} from "@fortawesome/free-solid-svg-icons";


export const ALL_ICONS = {
    "ambulance" : { lib: 'fontawesome', group: 'vehicle','icon': faAmbulance, iconName: 'ambulance' },
    "baby-carriage" : { lib: 'fontawesome', group: 'object','icon': faBabyCarriage, iconName: 'baby-carriage'},
    "bed" : { lib: 'fontawesome', group: 'other','icon': faBed, iconName: 'bed'},
    "light-bulb": { lib: 'fontawesome', group: 'object', 'icon': faLightbulb, iconName: 'light-bulb'},
    "star" : { lib: 'fontawesome', group: 'other','icon': faStar, iconName: 'star'},
    "snowflake" : { lib: 'fontawesome', group: 'other','icon': faSnowflake, iconName: 'snowflake'},
    "bicycle" : { lib: 'fontawesome', group: 'vehicle','icon': faBicycle, iconName: 'bicycle'},
    "book": { lib: 'fontawesome', group: 'object', 'icon': faBook, iconName: 'book'},
    "box" : { lib: 'fontawesome', group: 'object','icon': faBox, iconName: 'box'},
    "building" : { lib: 'fontawesome', group: 'building','icon': faBuilding, iconName: 'building'},
    "bus" : { lib: 'fontawesome', group: 'vehicle','icon': faBus, iconName: 'bus'},
    "bus-alt" : { lib: 'fontawesome', group: 'vehicle','icon': faBusAlt, iconName: 'bus-alt'},
    "camera" : { lib: 'fontawesome', group: 'object','icon': faCamera, iconName: 'camera'},
    "campground" : { lib: 'fontawesome', group: 'other','icon': faCampground, iconName: 'campground'},
    "car" : { lib: 'fontawesome', group: 'vehicle','icon': faCar, iconName: 'car'},
    "car-side" : { lib: 'fontawesome', group: 'vehicle','icon': faCarSide, iconName: 'car-side'},
    "caravan" : { lib: 'fontawesome', group: 'vehicle','icon': faCaravan, iconName: 'caravan'},
    "carrot" : { lib: 'fontawesome', group: 'other','icon': faCarrot, iconName: 'carrot'},
    "cat" : { lib: 'fontawesome', group: 'animal','icon': faCat, iconName: 'cat'},
    "coffee" : { lib: 'fontawesome', group: 'other','icon': faCoffee, iconName: 'coffee'},
    "couch" : { lib: 'fontawesome', group: 'object','icon': faCouch, iconName: 'couch'},
    "crow" : { lib: 'fontawesome', group: 'animal','icon': faCrow, iconName: 'crow'},
    "desktop" : { lib: 'fontawesome', group: 'object','icon': faDesktop, iconName: 'desktop'},
    "dice" : { lib: 'fontawesome', group: 'other','icon': faDice, iconName: 'dice'},
    "dog" : { lib: 'fontawesome', group: 'animal','icon': faDog, iconName: 'dog'},
    "dolly" : { lib: 'fontawesome', group: 'tool','icon': faDolly, iconName: 'dolly'},
    "door-open" : { lib: 'fontawesome', group: 'building','icon': faDoorOpen, iconName: 'door-open'},
    "drum" : { lib: 'fontawesome', group: 'object','icon': faDrum, iconName: 'drum'},
    "envelope" : { lib: 'fontawesome', group: 'other','icon': faEnvelope, iconName: 'envelope'},
    "exclamation-triangle" : { lib: 'fontawesome', group: 'other','icon': faExclamationTriangle, iconName: 'exclamation-triangle'},
    "eye" : { lib: 'fontawesome', group: 'other','icon': faEye, iconName: 'eye'},
    "feather" : { lib: 'fontawesome', group: 'animal','icon': faFeather, iconName: 'feather'},
    "gamepad" : { lib: 'fontawesome', group: 'object','icon': faGamepad, iconName: 'gamepad'},
    "gas-pump" : { lib: 'fontawesome', group: 'vehicle','icon': faGasPump, iconName: 'gas-pump'},
    "gift" : { lib: 'fontawesome', group: 'other','icon': faGift, iconName: 'gift'},
    "glasses" : { lib: 'fontawesome', group: 'object','icon': faGlasses, iconName: 'glasses'},
    "globe" : { lib: 'fontawesome', group: 'other','icon': faGlobe, iconName: 'globe'},
    "globe-europe" : { lib: 'fontawesome', group: 'other','icon': faGlobeEurope, iconName: 'globe-europe'},
    "guitar" : { lib: 'fontawesome', group: 'object','icon': faGuitar, iconName: 'guitar'},
    "hamburger" : { lib: 'fontawesome', group: 'other','icon': faHamburger, iconName: 'hamburger'},
    "hammer" : { lib: 'fontawesome', group: 'tool','icon': faHammer, iconName: 'hammer'},
    "handshake" : { lib: 'fontawesome', group: 'other','icon': faHandshake, iconName: 'handshake'},
    "hard-hat" : { lib: 'fontawesome', group: 'tool','icon': faHardHat, iconName: 'hard-hat'},
    "hat-cowboy" : { lib: 'fontawesome', group: 'object','icon': faHatCowboy, iconName: 'hat-cowboy'},
    "headphones-alt" : { lib: 'fontawesome', group: 'object','icon': faHeadphonesAlt, iconName: 'headphones-alt'},
    "helicopter" : { lib: 'fontawesome', group: 'vehicle','icon': faHelicopter, iconName: 'helicopter'},
    "home" : { lib: 'fontawesome', group: 'building','icon': faHome, iconName: 'home'},
    "horse" : { lib: 'fontawesome', group: 'animal','icon': faHorse, iconName: 'horse'},
    "hotel" : { lib: 'fontawesome', group: 'building','icon': faHotel, iconName: 'hotel'},
    "hourglass" : { lib: 'fontawesome', group: 'other','icon': faHourglass, iconName: 'hourglass'},
    "ice-cream" : { lib: 'fontawesome', group: 'other','icon': faIceCream, iconName: 'ice-cream'},
    "laptop" : { lib: 'fontawesome', group: 'object','icon': faLaptop, iconName: 'laptop'},
    "leaf" : { lib: 'fontawesome', group: 'other','icon': faLeaf, iconName: 'leaf'},
    "life-ring" : { lib: 'fontawesome', group: 'other','icon': faLifeRing, iconName: 'life-ring'},
    "mobile-alt" : { lib: 'fontawesome', group: 'object','icon': faMobileAlt, iconName: 'mobile-alt'},
    "motorcycle" : { lib: 'fontawesome', group: 'vehicle','icon': faMotorcycle, iconName: 'motorcycle'},
    "mug-hot" : { lib: 'fontawesome', group: 'other','icon': faMugHot, iconName: 'mug-hot'},
    "paw" : { lib: 'fontawesome', group: 'animal','icon': faPaw, iconName: 'paw'},
    "plane" : { lib: 'fontawesome', group: 'vehicle','icon': faPlane, iconName: 'plane'},
    "plug" : { lib: 'fontawesome', group: 'other','icon': faPlug, iconName: 'plug'},
    "receipt" : { lib: 'fontawesome', group: 'other','icon': faReceipt, iconName: 'receipt'},
    "record-vinyl" : { lib: 'fontawesome', group: 'object','icon': faRecordVinyl, iconName: 'record-vinyl'},
    "recycle" : { lib: 'fontawesome', group: 'other','icon': faRecycle, iconName: 'recycle'},
    "robot" : { lib: 'fontawesome', group: 'other','icon': faRobot, iconName: 'robot'},
    "rocket" : { lib: 'fontawesome', group: 'other','icon': faRocket, iconName: 'rocket'},
    "seedling" : { lib: 'fontawesome', group: 'other','icon': faSeedling, iconName: 'seedling'},
    "ship" : { lib: 'fontawesome', group: 'vehicle','icon': faShip, iconName: 'ship'},
    "shield-alt" : { lib: 'fontawesome', group: 'other','icon': faShieldAlt, iconName: 'shield-alt'},
    "shopping-bag" : { lib: 'fontawesome', group: 'object','icon': faShoppingBag, iconName: 'shopping-bag'},
    "shopping-basket" : { lib: 'fontawesome', group: 'object','icon': faShoppingBasket, iconName: 'shopping-basket'},
    "shopping-cart" : { lib: 'fontawesome', group: 'object','icon': faShoppingCart, iconName: 'shopping-cart'},
    "shuttle-van" : { lib: 'fontawesome', group: 'vehicle','icon': faShuttleVan, iconName: 'shuttle-van'},
    "snowplow" : { lib: 'fontawesome', group: 'vehicle','icon': faSnowplow, iconName: 'snowplow'},
    "socks" : { lib: 'fontawesome', group: 'object','icon': faSocks, iconName: 'socks'},
    "solar-panel" : { lib: 'fontawesome', group: 'other','icon': faSolarPanel, iconName: 'solar-panel'},
    "store" : { lib: 'fontawesome', group: 'building','icon': faStore, iconName: 'store'},
    "suitcase" : { lib: 'fontawesome', group: 'object','icon': faSuitcase, iconName: 'suitcase'},
    "sun" : { lib: 'fontawesome', group: 'other','icon': faSun, iconName: 'sun'},
    "tablet-alt" : { lib: 'fontawesome', group: 'object','icon': faTabletAlt, iconName: 'tablet-alt'},
    "tag" : { lib: 'fontawesome', group: 'other','icon': faTag, iconName: 'tag'},
    "theater-masks" : { lib: 'fontawesome', group: 'other','icon': faTheaterMasks, iconName: 'theater-masks'},
    "toolbox" : { lib: 'fontawesome', group: 'tool','icon': faToolbox, iconName: 'toolbox'},
    "tools" : { lib: 'fontawesome', group: 'tool','icon': faTools, iconName: 'tools'},
    "tractor" : { lib: 'fontawesome', group: 'vehicle','icon': faTractor, iconName: 'tractor'},
    "trailer" : { lib: 'fontawesome', group: 'vehicle','icon': faTrailer, iconName: 'trailer'},
    "trash" : { lib: 'fontawesome', group: 'object','icon': faTrash, iconName: 'trash'},
    "trophy" : { lib: 'fontawesome', group: 'object','icon': faTrophy, iconName: 'trophy'},
    "truck-pickup" : { lib: 'fontawesome', group: 'vehicle','icon': faTruckPickup, iconName: 'truck-pickup'},
    "truck" : { lib: 'fontawesome', group: 'vehicle','icon': faTruck, iconName: 'truck'},
    "tshirt" : { lib: 'fontawesome', group: 'object','icon': faTshirt, iconName: 'tshirt'},
    "umbrella" : { lib: 'fontawesome', group: 'object','icon': faUmbrella, iconName: 'umbrella'},
    "unlock" : { lib: 'fontawesome', group: 'other','icon': faUnlock, iconName: 'unlock'},
    "lock" : { lib: 'fontawesome', group: 'other','icon': faLock, iconName: 'lock'},
    "utensils" : { lib: 'fontawesome', group: 'object','icon': faUtensils, iconName: 'utensils'},
    "vest" : { lib: 'fontawesome', group: 'object','icon': faVest, iconName: 'vest'},
    "video" : { lib: 'fontawesome', group: 'object','icon': faVideo, iconName: 'video'},
    "volleyball-ball" : { lib: 'fontawesome', group: 'object','icon': faVolleyballBall, iconName: 'volleyball-ball'},
    "weight-hanging" : { lib: 'fontawesome', group: 'tool','icon': faWeightHanging, iconName: 'weight-hanging'},
    "whine-bottle" : { lib: 'fontawesome', group: 'other','icon': faWineBottle, iconName: 'whine-bottle'},
    "wrench" : { lib: 'fontawesome', group: 'tool','icon': faWrench, iconName: 'wrench'},
}